// Package Imports
import React, { createContext, useContext, useEffect, useState } from 'react';
// Project Imports
import { MasterSourceContextType } from 'contexts/resource/masterSourceContext/masterSourceContextType';

const MasterSourceContext = createContext<MasterSourceContextType>({
  masterSelectFile: null,
  setMasterSelectFile() {
    throw new Error('setMasterCurrent function is not implemented');
  },
  masterCurrent: 0,
  setMasterCurrent() {
    throw new Error('setMasterCurrent function is not implemented');
  },
  isMasterFirstStepCompleted: false,
  setIsMasterFirstStepCompleted() {
    throw new Error(
      'setIsMasterFirstStepCompleted function is not implemented'
    );
  },
  isMasterSecondStepCompleted: false,
  setIsMasterSecondStepCompleted() {
    throw new Error(
      'setIsMasterSecondStepCompleted function is not implemented'
    );
  },
  setMasterSourceId() {
    throw new Error('setMasterCurrent function is not implemented');
  },
  masterSourceId: null,
  setMasterSourceFileData() {
    throw new Error('setMasterSourceFileData  function is not implemented');
  },
  masterSourceFileData: null,
  masterSelectFileObj: null,
  setMasterSelectFileObj() {
    throw new Error('setMasterSelectFileObj function is not implemented');
  },
  dbData: null,
  setDbData() {
    throw new Error('SetDbData function is not implemented');
  },
});

export function useMasterSourceContext() {
  return useContext(MasterSourceContext);
}

const MasterSourceProvider = ({ children }: { children: React.ReactNode }) => {
  const [masterCurrent, setMasterCurrent] = useState<any>(() => {
    const storedCurrent = localStorage.getItem('masterCurrent');
    return Number(storedCurrent) || 0;
  });
  const [masterSelectFile, setMasterSelectFile] = useState(
    () => localStorage.getItem('masterSelectFile') || ''
  );
  const [masterSelectFileObj, setMasterSelectFileObj] = useState(() => {
    const storedSelectFileObj = localStorage.getItem('masterSelectFileObj');
    return storedSelectFileObj ? JSON.parse(storedSelectFileObj) : {};
  });
  const [masterSourceId, setMasterSourceId] = useState<any>(
    () => localStorage.getItem('masterSourceId') || null
  );
  const [masterSourceFileData, setMasterSourceFileData] = useState(() => {
    const storedinputSourceFile = localStorage.getItem('masterSourceFileData');
    return storedinputSourceFile ? JSON.parse(storedinputSourceFile) : '';
  });

  const [isMasterFirstStepCompleted, setIsMasterFirstStepCompleted] = useState(
    () => localStorage.getItem('isMasterFirstStepCompleted') === 'true'
  );
  const [isMasterSecondStepCompleted, setIsMasterSecondStepCompleted] =
    useState(
      () => localStorage.getItem('isMasterSecondStepCompleted') === 'true'
    );
  const [dbData, setDbData] = useState<any>(() => {
    const storedDbData = localStorage.getItem('dbData');
    return storedDbData ? JSON.parse(storedDbData) : {};
  });
  useEffect(() => {
    localStorage.setItem('masterCurrent', masterCurrent);
  }, [masterCurrent]);

  useEffect(() => {
    localStorage.setItem('masterSelectFile', masterSelectFile);
  }, [masterSelectFile]);

  useEffect(() => {
    localStorage.setItem(
      'masterSelectFileObj',
      JSON.stringify(masterSelectFileObj)
    );
  }, [masterSelectFileObj]);

  useEffect(() => {
    localStorage.setItem('masterSourceId', masterSourceId);
  }, [masterSourceId]);

  useEffect(() => {
    console.log(masterSourceFileData, 'inputSourceFileData');
    localStorage.setItem(
      'inputSourceFileData',
      JSON.stringify(masterSourceFileData)
    );
  }, [masterSourceFileData]);

  useEffect(() => {
    localStorage.setItem(
      'isMasterFirstStepCompleted',
      isMasterFirstStepCompleted === true ? 'true' : 'false'
    );
  }, [isMasterFirstStepCompleted]);

  useEffect(() => {
    localStorage.setItem(
      'isMasterSecondStepCompleted',
      isMasterSecondStepCompleted === true ? 'true' : 'false'
    );
  }, [isMasterSecondStepCompleted]);

  useEffect(() => {
    console.log(dbData, 'dbData');
    localStorage.setItem('dbData', JSON.stringify(dbData));
  }, [dbData]);
  return (
    <MasterSourceContext.Provider
      value={{
        masterSelectFile,
        setMasterSelectFile,
        masterSelectFileObj,
        setMasterSelectFileObj,
        masterCurrent,
        setMasterCurrent,
        setMasterSourceId,
        masterSourceId,
        isMasterFirstStepCompleted,
        setIsMasterFirstStepCompleted,
        isMasterSecondStepCompleted,
        setIsMasterSecondStepCompleted,
        setMasterSourceFileData,
        masterSourceFileData,
        dbData,
        setDbData,
      }}
    >
      {children}
    </MasterSourceContext.Provider>
  );
};

export default MasterSourceProvider;
