// Package Imports
import React, { Suspense, lazy } from 'react';
import { Route } from 'react-router-dom';

// Project Imports
import routeName from 'core/common/routeName';
import PrivateRoute from 'core/utils/PrivateRoute';
import PrivateRouteConst from 'core/common/privateRouteConstant';
import Loading from 'components/loading/loading';

// Lazy-loaded Components
const AddCustomColumn = lazy(
  () =>
    import('pages/inputSource/customColumns/addCustomColumn/addCustomColumn')
);
const EditCustomColumn = lazy(
  () =>
    import('pages/inputSource/customColumns/addCustomColumn/editCustomColumn')
);

const InputCustomColumnsRoute = (
  <Route path={routeName.inputColumns}>
    <Route
      path="create/:id"
      element={
        <PrivateRoute name={PrivateRouteConst.CREATE_INPUT_CUSTOM_COLUMN}>
          <Suspense fallback={<Loading />}>
            <AddCustomColumn />
          </Suspense>
        </PrivateRoute>
      }
    />
    <Route
      path="edit/:id"
      element={
        <PrivateRoute
          name={[
            PrivateRouteConst.INPUT_CUSTOM_COLUMN_ITEM_EDIT,
            PrivateRouteConst.SELF_INPUT_CUSTOM_COLUMN_ITEM_EDIT,
          ]}
        >
          <Suspense fallback={<Loading />}>
            <EditCustomColumn />
          </Suspense>
        </PrivateRoute>
      }
    />
    <Route
      path="show/:id"
      element={
        <PrivateRoute
          name={[
            PrivateRouteConst.INPUT_CUSTOM_COLUMN_ITEM_VIEW,
            PrivateRouteConst.SELF_INPUT_CUSTOM_COLUMN_ITEM_VIEW,
          ]}
        >
          <Suspense fallback={<Loading />}>
            <EditCustomColumn pageType="Show" />
          </Suspense>
        </PrivateRoute>
      }
    />
  </Route>
);

export default InputCustomColumnsRoute;
