const SocketEvent = {
  INPUT_SOURCE_CREATION_EVENT_NAME: 'Input Configuration Creation',
  INPUT_CONFIG_FILE_UPLOAD_EVENT_NAME: 'Input Configuration File Upload',
  INPUT_CUSTOM_COLUMN_CREATION_EVENT_NAME: 'Input Custom Column Creation',

  MASTER_SOURCE_CREATION_EVENT_NAME: 'Master Configuration Creation',
  MASTER_CONFIG_FILE_UPLOAD_EVENT_NAME: 'Master Configuration File Upload',

  UNIFIED_SOURCE_CREATION_EVENT_NAME: 'Unified Data Configuration',
  UNIFIED_CUSTOM_COLUMN_CREATION_EVENT_NAME: 'Unified Custom Column Creation',
};

export default SocketEvent;
